<template>
  <div>
    <el-form :model="formData" label-width="200px">
      <el-row>
        <el-form-item label="资产码：">
          <el-input ref="assetSn" v-model="formData.assetSn" placeholder="请输入或扫资产码" class="form-item"></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="测试方法：">
          <el-select v-model="formData.testType" placeholder="测试方法" class="form-item" clearable filterable>
            <el-option v-for="item in doaTestTypeList" :key="item.value" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="测试结果：">
          <el-radio-group v-model="formData.testResult">
            <el-radio :label="true">正常</el-radio>
            <el-radio :label="false">不良</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="本次进站故障：">
          <el-radio-group v-model="formData.thisTimeTrouble">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="是否与DOA故障一致：">
          <el-radio-group v-model="formData.doaSame">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-row>
      <el-row v-if="formData.doaSame===false">
        <el-form-item label="本次故障描述：">
          <el-input type="textarea" v-model="formData.doaReason" placeholder="请输入本次故障描述" class="form-item"></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item>
          <el-button type="primary" @click="submit" :loading="loading">提交</el-button>
          <MessageComponent ref="commitMessage"/>
        </el-form-item>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import MessageComponent from "@/components/MessageComponent.vue";

export default {
  name: "DoaWorkRecord",
  components: {MessageComponent},
  data() {
    return {
      formData: {
        assetSn: '',
        testResult: undefined,
        testType: '',
        thisTimeTrouble: undefined,
        doaSame: undefined,
        doaReason: ''
      },
      loading: false
    }
  },
  computed: {
    doaTestTypeList() {
      return this.$store.getters.getDictList('doaTestType') || [];
    }
  },
  methods: {
    submit() {
      this.$refs.commitMessage.clear();

      this.formData.assetSn = this.formData.assetSn.trim()

      if (!this.formData.assetSn) {
        return this.$refs.commitMessage.showError('请输入资产码');
      }

      if (!this.formData.testType) {
        return this.$refs.commitMessage.showError('请选择测试方法');
      }

      if (this.formData.testResult !== false && this.formData.testResult !== true) {
        return this.$refs.commitMessage.showError('请选择测试结果');
      }

      if (this.formData.thisTimeTrouble !== false && this.formData.thisTimeTrouble !== true) {
        return this.$refs.commitMessage.showError('请选择本次进站故障');
      }

      if (this.formData.doaSame !== false && this.formData.doaSame !== true) {
        return this.$refs.commitMessage.showError('请选择是否与DOA故障一致');
      }

      if (this.formData.doaSame === false) {
        if (!this.formData.doaReason) {
          return this.$refs.commitMessage.showError('请输入本次故障描述');
        }
      } else {
        this.formData.doaReason = ''
      }

      this.loading = true
      this.$axios.post('doaItem/saveResult', this.formData).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$refs.commitMessage.showError(res.message);
        }

        this.$refs.commitMessage.showSuccess('作业提交成功')
        this.formData = {}

      }, error => {
        this.loading = false;
        return this.$refs.commitMessage.showError('提交失败，' + error.message);
      });

    }
  }
}
</script>

<style scoped>
.form-item {
  width: 300px;
}
</style>